import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`1st February 2021`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now register/discover your servers to/from `}<a parentName="p" {...{
            "href": "https://www.consul.io/"
          }}>{`Consul`}</a>{`
using a new `}<inlineCode parentName="p">{`armeria-consul`}</inlineCode>{` module. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/194"
          }}>{`#194`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2192"
          }}>{`#2192`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3002"
          }}>{`#3002`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3281"
          }}>{`#3281`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Registration
Server server = ...;
server.addListener(
  ConsulUpdatingListener.builder(myConsulUri, "myService")
                        .consulToken(myConsulAccessToken)
                        .build());
server.start().join();

// Discovery
ConsulEndpointGroup endpointGroup =
  ConsulEndpointGroup.builder(myConsulUri, "myService")
                     .consulToken(myConsulAccessToken)
                     .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now smoothly ramp up the weight of newly added `}<a parentName="p" {...{
            "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
          }}>{`type://Endpoint`}</a>{` in
an `}<a parentName="p" {...{
            "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
          }}>{`type://EndpointGroup`}</a>{` using `}<a parentName="p" {...{
            "href": "type://EndpointSelectionStrategy#rampingUp():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionStrategy.html#rampingUp()"
          }}>{`type://EndpointSelectionStrategy#rampingUp()`}</a>{`
to protect a fresh node from getting too much traffic suddenly. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1757"
          }}>{`#1757`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3217"
          }}>{`#3217`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`EndpointGroup endpointGroup =
  DnsAddressEndpointGroup
    .builder("my.hostname.local")
    .selectionStrategy(EndpointSelectionStrategy.rampingUp())
    .build();

// Customize as necessary.
EndpointSelectionStrategy
  .builderForRampingUp()
  // Ramp up a fresh endpoint for 60 seconds,
  // increasing weight every second.
  .rampingUpInterval(Duration.ofSeconds(1))
  .numberSteps(60)
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now start your server as unhealthy with
`}<a parentName="p" {...{
            "href": "type://HealthCheckServiceBuilder#startUnhealthy():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthCheckServiceBuilder.html#startUnhealthy()"
          }}>{`type://HealthCheckServiceBuilder#startUnhealthy()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3260"
          }}>{`#3260`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`This can be useful when you don't want to handle incoming requests immediately
after startup but after some operational steps, such as warming up.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now add `}<a parentName="p" {...{
            "href": "typeplural://HealthCheckUpdateListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthCheckUpdateListener.html"
          }}>{`typeplural://HealthCheckUpdateListener`}</a>{` to
`}<a parentName="p" {...{
            "href": "type://HealthCheckService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthCheckService.html"
          }}>{`type://HealthCheckService`}</a>{` to get notified when healthiness is updated externally,
e.g., from a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request handled by `}<a parentName="p" {...{
            "href": "type://HealthCheckUpdateHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthCheckUpdateHandler.html"
          }}>{`type://HealthCheckUpdateHandler`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3260"
          }}>{`#3260`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`This can be useful when used in combination with `}<inlineCode parentName="li">{`startUnhealthy()`}</inlineCode>{` above.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify a custom HTTP header naming rule for HTTP/1 connections,
so that you can work around the interoperability issues with old HTTP/1 servers
that don't recognize lower-cased headers such as `}<inlineCode parentName="p">{`content-length`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3196"
          }}>{`#3196`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3259"
          }}>{`#3259`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory factory =
  ClientFactory.builder()
               // Send 'Content-Length', not 'content-length'.
               .http1HeaderNaming(http1HeaderNaming.traditional())
               .build();
WebClient client =
  WebClient.builder()
           .factory(factory)
           .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now close a connection after handling a certain number of requests or
after a certain period of time, rather than keeping it open indefinitely. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/203"
          }}>{`#203`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3267"
          }}>{`#3267`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "/release-notes/0.99.7"
              }}>{`v0.99.7`}</a>{` introduced this feature partially for the server
side. We expanded it into both client and server side and also added a way
to disconnect after handling a certain number of requests.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// For server
Server.builder()
      // A connection will be closed after
      // sending the 10000th request's response.
      .maxNumRequestsPerConnection(10000);

// For client
ClientFactory.builder()
             // A connection will be closed after
             // receiving the 2000th request's response.
             .maxNumRequestsPerConnection(2000);
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now turn any service into a transient service by decorating it with
`}<a parentName="p" {...{
            "href": "type://TransientHttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/TransientHttpService.html"
          }}>{`type://TransientHttpService`}</a>{` or `}<a parentName="p" {...{
            "href": "type://TransientRpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/TransientRpcService.html"
          }}>{`type://TransientRpcService`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3221"
          }}>{`#3221`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`By making a service transient, you can disable metric collection by
`}<a parentName="li" {...{
              "href": "type://MetricCollectingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/MetricCollectingService.html"
            }}>{`type://MetricCollectingService`}</a>{`, service logging by `}<a parentName="li" {...{
              "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
            }}>{`type://LoggingService`}</a>{` or
access logging by `}<a parentName="li" {...{
              "href": "type://AccessLogWriter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/AccessLogWriter.html"
            }}>{`type://AccessLogWriter`}</a>{`.`}</li>
          <li parentName="ul">{`The following example disables metric collection, service and access logs for
`}<a parentName="li" {...{
              "href": "type://HealthCheckService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthCheckService.html"
            }}>{`type://HealthCheckService`}</a>{`:`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`Server.builder()
      .serviceUnder(
        "/internal/l7check",
        HealthCheckService.of()
                          .decorate(TransientHttpService.newDecorator()))
      .build()
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now disable the check that rejects potentially unsafe TLS cipher suites
when configuring server-side TLS, at your own risk. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3292"
          }}>{`#3292`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .tlsAllowUnsafeCiphers()
      .tlsCustomizer(sslCtxBuilder -> {
        sslCtxBuilder.ciphers(Set.of(
          "TLS_ECDHE_RSA_WITH_AES_256_CBC_SHA" // Bad cipher suite
        ))
      })
      ...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now get, set and parse a `}<inlineCode parentName="p">{`Content-Disposition`}</inlineCode>{` header using
`}<a parentName="p" {...{
            "href": "type://ContentDisposition:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContentDisposition.html"
          }}>{`type://ContentDisposition`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3253"
          }}>{`#3253`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Setting
HttpHeaders headers = HttpHeaders.of(
  HttpHeaderNames.CONTENT_DISPOSITION,
  ContentDisposition.of("attachment", "fileName", "file.png"));

// Getting
ContentDisposition disposition = headers.contentDisposition();
assert "attachment".equals(disposition.type());
assert "fileName".equals(disposition.name());
assert "file.png".equals(disposition.filename());

// Parsing
ContentDisposition parsed = ContentDisposition.parse(
  "attachment; name=\\"fileName\\"; filename=\\"file.png\\"");
assert parsed.equals(disposition);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now serve a file by returning an `}<a parentName="p" {...{
            "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
          }}>{`type://HttpFile`}</a>{` in an annotated service. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3258"
          }}>{`#3258`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3287"
          }}>{`#3287`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .annotatedService(new Object() {
    @Get
    @Head
    @Path("/files/{fileName}")
    HttpFile myFile(@Param String fileName) {
      return HttpFile.of(new File("/var/www/" + fileName));
    }
  })
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now invoke `}<inlineCode parentName="p">{`Runnable`}</inlineCode>{` or `}<inlineCode parentName="p">{`Callable`}</inlineCode>{` with `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` set in
the thread-local storage using `}<a parentName="p" {...{
            "href": "type://RequestContext#run(Runnable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#run(java.lang.Runnable)"
          }}>{`type://RequestContext#run(Runnable)`}</a>{` and
`}<a parentName="p" {...{
            "href": "type://RequestContext#run(Callable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#run(java.util.concurrent.Callable)"
          }}>{`type://RequestContext#run(Callable)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3311"
          }}>{`#3311`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Runnable task = () -> {
  logger.info("Current request: {}", ServiceRequestContext.current());
};
ctx.run(task);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now concat multiple `}<inlineCode parentName="p">{`Publisher`}</inlineCode>{`s into one using
`}<a parentName="p" {...{
            "href": "type://StreamMessage#concat(Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#concat(java.lang.Iterable)"
          }}>{`type://StreamMessage#concat(Iterable)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3254"
          }}>{`#3254`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3299"
          }}>{`#3299`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`StreamMessage<String> stream =
  StreamMessage.concat(Mono.just("foo"),
                       Mono.just("bar"));

StepVerifier.create(stream)
            .expectNext("foo")
            .expectNext("bar")
            .expectComplete()
            .verify()

// You can also concat a \`Publisher\` of \`Publisher\`s.
StreamMessage<String> flat =
  StreamMessage.concat(Flux.just(Mono.just("foo"),
                                 Mono.just("bar")));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now create an `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` from a `}<a parentName="p" {...{
            "href": "type://ResponseHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ResponseHeaders.html"
          }}>{`type://ResponseHeaders`}</a>{` and
a stream of `}<a parentName="p" {...{
            "href": "typeplural://HttpData:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpData.html"
          }}>{`typeplural://HttpData`}</a>{` using
`}<a parentName="p" {...{
            "href": "type://HttpResponse#of(ResponseHeaders,Publisher):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#of(com.linecorp.armeria.common.ResponseHeaders,org.reactivestreams.Publisher)"
          }}>{`type://HttpResponse#of(ResponseHeaders,Publisher)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3089"
          }}>{`#3089`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3237"
          }}>{`#3237`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpResponse res = HttpResponse.of(
  ResponseHeaders.of(200),
  Flux.just("one\\n", "two\\n", "three\\n")
      .map(HttpData::ofUtf8));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now check which native transports are available or not and why they are
unavailable using `}<a parentName="p" {...{
            "href": "type://TransportType#isAvailable():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/TransportType.html#isAvailable()"
          }}>{`type://TransportType#isAvailable()`}</a>{` and
`}<a parentName="p" {...{
            "href": "type://TransportType#unavailabilityCause():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/TransportType.html#unavailabilityCause()"
          }}>{`type://TransportType#unavailabilityCause()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3244"
          }}>{`#3244`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`if (!TransportType.EPOLL.isAvailable()) {
  logger.warn("/dev/epoll support not available:",
              TransportType.EPOLL.unavailabilityCause());
}
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`We also added methods like `}<inlineCode parentName="li">{`serverChannelType()`}</inlineCode>{`, `}<inlineCode parentName="li">{`socketChannelType()`}</inlineCode>{` and
`}<inlineCode parentName="li">{`datagramChannelType()`}</inlineCode>{`, which may be useful when you need to deal with Netty
channels.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now decode the body of `}<a parentName="p" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{` or `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` into
a `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` using `}<a parentName="p" {...{
            "href": "type://HttpMessage#decode(HttpDecoder):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpMessage.html#decode(com.linecorp.armeria.common.stream.HttpDecoder)"
          }}>{`type://HttpMessage#decode(HttpDecoder)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3215"
          }}>{`#3215`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`This feature can be useful when you need to reactively decode an HTTP body.
For example, Armeria will use this feature to handle a multipart body.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<a parentName="p" {...{
            "href": "type://StreamMessage#demand():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#demand()"
          }}>{`type://StreamMessage#demand()`}</a>{` that returns the remaining demand of
the current subscription. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3215"
          }}>{`#3215`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<inlineCode parentName="p">{`withTags(Tag...)`}</inlineCode>{` method to `}<a parentName="p" {...{
            "href": "type://MeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefixFunction.html"
          }}>{`type://MeterIdPrefixFunction`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3241"
          }}>{`#3241`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3242"
          }}>{`#3242`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now register a custom gRPC client stub factory via SPI or
`}<a parentName="p" {...{
            "href": "type://GrpcClientOptions#GRPC_CLIENT_STUB_FACTORY:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/grpc/GrpcClientOptions.html#GRPC_CLIENT_STUB_FACTORY"
          }}>{`type://GrpcClientOptions#GRPC_CLIENT_STUB_FACTORY`}</a>{` option. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3214"
          }}>{`#3214`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3294"
          }}>{`#3294`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now keep using the old decoration ordering for route decorators by
specifying the `}<inlineCode parentName="p">{`-Dcom.linecorp.armeria.useLegacyRouteDecoratorOrdering=true`}</inlineCode>{`
JVM option. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3279"
          }}>{`#3279`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`You can now run your Armeria application even if `}<inlineCode parentName="li">{`netty-transport-native-epoll`}</inlineCode>{` and
`}<inlineCode parentName="li">{`netty-incubator-transport-native-io_uring`}</inlineCode>{` are not in the class path. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3244"
        }}>{`#3244`}</a></li>
      <li parentName="ul">{`You can now use `}<inlineCode parentName="li">{`/dev/epoll`}</inlineCode>{` transport on WSL 2. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3244"
        }}>{`#3244`}</a></li>
      <li parentName="ul">{`You can now use `}<a parentName="li" {...{
          "href": "type://JettyService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyService.html"
        }}>{`type://JettyService`}</a>{` with Jetty 9.3. We previously supported
Jetty 9.4 only. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3288"
        }}>{`#3288`}</a></li>
      <li parentName="ul">{`We improved how we shut down a `}<a parentName="li" {...{
          "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
        }}>{`type://Server`}</a>{` in our Spring Boot integration
so it's more future-proof. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3266"
        }}>{`#3266`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`The future returned by `}<a parentName="li" {...{
          "href": "type://StreamWriter#whenConsumed():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamWriter.html#whenConsumed()"
        }}>{`type://StreamWriter#whenConsumed()`}</a>{` is now completed
immediately even when the current demand is `}<inlineCode parentName="li">{`0`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3213"
        }}>{`#3213`}</a></li>
      <li parentName="ul">{`Fixed the violation of Reactive Streams specification when `}<inlineCode parentName="li">{`null`}</inlineCode>{` is published. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3212"
        }}>{`#3212`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3216"
        }}>{`#3216`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
        }}>{`type://TomcatService`}</a>{` does not raise a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` when it stops.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3136"
        }}>{`#3136`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3218"
        }}>{`#3218`}</a></li>
      <li parentName="ul">{`Fixed stability and interoperability issues in Eureka service discovery module.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3235"
        }}>{`#3235`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3275"
        }}>{`#3275`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3238"
        }}>{`#3238`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3301"
        }}>{`#3301`}</a></li>
      <li parentName="ul">{`The service name generated from a CGLIB-enhanced class does not have synthetic
suffix anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3103"
        }}>{`#3103`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3240"
        }}>{`#3240`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HealthCheckedEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/healthcheck/HealthCheckedEndpointGroup.html"
        }}>{`type://HealthCheckedEndpointGroup`}</a>{` now refreshes its `}<a parentName="li" {...{
          "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`typeplural://Endpoint`}</a>{`'
status when their weights are changed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3236"
        }}>{`#3236`}</a></li>
      <li parentName="ul">{`You'll no longer see a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` when retrying a gRPC request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3251"
        }}>{`#3251`}</a></li>
      <li parentName="ul">{`Fixed the incorrect return type of some builder methods. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3220"
        }}>{`#3220`}</a></li>
      <li parentName="ul">{`Fixed a UI glitch in the go-to form of `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` client `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3261"
        }}>{`#3261`}</a></li>
      <li parentName="ul">{`You'll no longer see a harmless `}<inlineCode parentName="li">{`Http2Exception: Stream N does not exist`}</inlineCode>{`
warning log message anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3233"
        }}>{`#3233`}</a>
        <ul parentName="li">
          <li parentName="ul">{`You'll see it if there was really a protocol violation, however.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Fixed an issue where a `}<a parentName="li" {...{
          "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
        }}>{`type://RequestLog`}</a>{` is sometimes not completed immediately. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3291"
        }}>{`#3291`}</a></li>
      <li parentName="ul">{`TLS is now configured only once in Spring Boot WebFlux integration. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3266"
        }}>{`#3266`}</a></li>
      <li parentName="ul">{`Armeria now finds the gRPC `}<inlineCode parentName="li">{`ServiceDescriptor`}</inlineCode>{` correctly from the code
generated with `}<a parentName="li" {...{
          "href": "https://github.com/salesforce/reactive-grpc"
        }}>{`Reactive-gRPC`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3294"
        }}>{`#3294`}</a></li>
      <li parentName="ul">{`A request refused with HTTP/2 `}<inlineCode parentName="li">{`REFUSED_STREAM`}</inlineCode>{` error code now fails with
an `}<a parentName="li" {...{
          "href": "type://UnprocessedRequestException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/UnprocessedRequestException.html"
        }}>{`type://UnprocessedRequestException`}</a>{`, so it can be safely retried. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3298"
        }}>{`#3298`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://THttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpService.html"
        }}>{`type://THttpService`}</a>{` can now handle the Thrift code generated with the `}<inlineCode parentName="li">{`fullcamel`}</inlineCode>{`
option. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3272"
        }}>{`#3272`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ZooKeeperUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperUpdatingListener.html"
        }}>{`type://ZooKeeperUpdatingListener`}</a>{` does not raise an `}<inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` anymore
even if the given Curator client is started already. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3305"
        }}>{`#3305`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`*ChannelType()`}</inlineCode>{` methods in `}<a parentName="li" {...{
          "href": "type://EventLoopGroups:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/EventLoopGroups.html"
        }}>{`type://EventLoopGroups`}</a>{` have been deprecated in favor of
the new methods in `}<a parentName="li" {...{
          "href": "type://TransportType:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/TransportType.html"
        }}>{`type://TransportType`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3244"
        }}>{`#3244`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CircuitBreakerRpcClient#newPerHostAndMethodDecorator()"
        }}>{`type://CircuitBreakerRpcClient#newPerHostAndMethodDecorator()`}</a>{` has been deprecated
in favor of `}<a parentName="li" {...{
          "href": "type://CircuitBreakerRpcClient#newDecorator(CircuitBreakerMapping,CircuitBreakerRuleWithContent):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRpcClient.html#newDecorator(com.linecorp.armeria.client.circuitbreaker.CircuitBreakerMapping,com.linecorp.armeria.client.circuitbreaker.CircuitBreakerRuleWithContent)"
        }}>{`type://CircuitBreakerRpcClient#newDecorator(CircuitBreakerMapping,CircuitBreakerRuleWithContent)`}</a>{`.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3249"
        }}>{`#3249`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`armeria-tomcat9`}</inlineCode>{` and `}<inlineCode parentName="li">{`armeria-tomcat8`}</inlineCode>{` don't depend on `}<inlineCode parentName="li">{`tomcat-embed-jasper`}</inlineCode>{`
anymore, which was pulled into runtime dependencies by mistake. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3256"
        }}>{`#3256`}</a></li>
      <li parentName="ul">{`You have to recompile your application because we changed the return type of
some builder methods. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3220"
        }}>{`#3220`}</a></li>
      <li parentName="ul">{`Changes in the API annotated with `}<a parentName="li" {...{
          "href": "type://@UnstableApi:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/annotation/UnstableApi.html"
        }}>{`type://@UnstableApi`}</a>{`:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`HttpDeframer`}</inlineCode>{` has been replaced with `}<a parentName="li" {...{
              "href": "type://HttpMessage#decode(HttpDecoder):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpMessage.html#decode(com.linecorp.armeria.common.stream.HttpDecoder)"
            }}>{`type://HttpMessage#decode(HttpDecoder)`}</a>{`. `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/3215"
            }}>{`#3215`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Bouncy Castle 1.67 → 1.68`}</li>
      <li parentName="ul">{`Brave 5.13.1 → 5.13.3`}</li>
      <li parentName="ul">{`Dropwizard 2.0.16 -> 2.0.18, 1.3.25 → 1.3.29`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.1.15 → 4.1.17`}</li>
      <li parentName="ul">{`gRPC-Java 1.33.1 → 1.35.0`}</li>
      <li parentName="ul">{`gRPC-Kotlin 0.2.1 → 1.0.0`}</li>
      <li parentName="ul">{`java-jwt 3.11.0 → 3.12.1`}</li>
      <li parentName="ul">{`Jetty 9.4.35 → 9.4.36`}</li>
      <li parentName="ul">{`Micrometer 1.6.1 → 1.6.3`}</li>
      <li parentName="ul">{`Netty 4.1.54 → 4.1.58`}
        <ul parentName="li">
          <li parentName="ul">{`tcnative 2.0.34 → 2.0.36`}</li>
          <li parentName="ul">{`io_uring 0.0.1 → 0.0.3`}
            <ul parentName="li">
              <li parentName="ul">{`io_uring is now an optional dependency.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Protobuf Jackson 1.1.0 → 1.2.0`}</li>
      <li parentName="ul">{`Project Reactor 3.4.0 → 3.4.2`}</li>
      <li parentName="ul">{`RxJava 3.0.7 → 3.0.9`}</li>
      <li parentName="ul">{`ScalaPB runtime 0.10.8 → 0.10.10`}</li>
      <li parentName="ul">{`ScalaPB json4s 0.10.1 → 0.10.3`}</li>
      <li parentName="ul">{`Spring Boot 2.4.0 → 2.4.1`}</li>
      <li parentName="ul">{`Tomcat 9.0.40 → 9.0.41, 8.5.58 → 8.5.61`}
        <ul parentName="li">
          <li parentName="ul">{`We don't depend on `}<inlineCode parentName="li">{`tomcat-embed-jasper`}</inlineCode>{` anymore. `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/3256"
            }}>{`#3256`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'arhont375', 'codefromthecrypt', 'delegacy', 'eisig', 'eugene70', 'ghkim3221', 'heowc', 'ikhoon', 'imasahiro', 'jongmin92', 'JunoJunho', 'KarboniteKream', 'kojilin', 'ks-kim', 'max904-github', 'minwoox', 'okue', 'policeman-kh', 'progresivoJS', 'renaudb', 'richieyan', 'snaiper80', 'syleeeee', 'tobias-', 'trustin', 'tumile', 'wickedev']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      